<template>
  <div v-if="bannerPhoto">
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" class="mb-4">
        <div class="font-weight-bold">{{'data.photo'| t}}</div>
        <div><br></div>
        <photo-selector
          disabled-remove
          type="banner"
          v-model="bannerPhoto.photo"
        ></photo-selector>
      </v-col>

      <v-col cols="12" xl="6" lg="6" md="6" class="mb-4">
        <div class="font-weight-bold">{{'data.photo_mobile'| t}}</div>
        <div class="body-2 light-blue--text">{{'data.photo_mobile.help'| t}}</div>
        <photo-selector
          type="banner"
          v-model="bannerPhoto.photo_mobile"
        ></photo-selector>
      </v-col>

      <v-col cols="12" xl="12" lg="12" md="12">
        <v-text-field
          dense
          outlined
          maxlength="100"
          counter
          :label="$t('data.title')"
          v-model="bannerPhoto.title"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xl="12" lg="12" md="12">
        <v-select
          dense
          outlined
          :items="textPositionItems"
          :label="$t('banner_photo.data.text_position')"
          v-model="bannerPhoto.text_position"
        ></v-select>
      </v-col>

      <v-col cols="12" xl="12" lg="12" md="12">
        <v-textarea
          dense
          outlined
          maxlength="300"
          counter
          :label="$t('data.brief')"
          v-model="bannerPhoto.brief"
        ></v-textarea>
      </v-col>


      <v-col cols="12" xl="8" lg="8" md="8">
        <v-text-field
          dense
          outlined
          maxlength="100"
          counter
          :label="$t('data.link')"
          v-model="bannerPhoto.link"
        ></v-text-field>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4" class="py-0">
        <v-switch
          dense
          color="green"
          inset
          :label="$t('data.target_blank')"
          v-model="bannerPhoto.blank"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    popupConfig: Object,
    data: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    bannerPhoto: null,
  }),
  created() {
    this.bannerPhoto = this.$eagleLodash.cloneDeep(this.data)
  },
  computed: {
    textPositionItems() {
      return ['top', 'middle', 'bottom'].map(position => ({
        text: this.$t(`banner_photo.data.text_position.${position}`),
        value: position,
      }))
    },
  },
  watch: {
    bannerPhoto: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', this.$eagleLodash.cloneDeep(this.bannerPhoto))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
